<template>
  <div class="container is-fluid">
    <!-- <BackgroundImage
      data-aos="fade"
      data-aos-delay="500"
      :useAlternateGradient="true"
      :filename="'2023/2023 - header'"
    /> -->
    <page-header
      :filename="'2023/2023 - header'"
      data-aos="fade"
      data-aos-delay="500"
      :useAlternateGradient="true"
    />

    <PaddingComponent :padding="16" />
    <HeroComponent data-aos="fade" data-aos-delay="1000" />
    <PaddingComponent :padding="16" />
    <!-- 2023 -->
    <YearEntry :showTopLine="false">
      <YearComponent class="is-secondary" :year="'2023'" />
      <NewsletterTimestampComponent
        class="is-secondary"
        :date="$t('d1')"
        :place="$t('l1')"
        :time="$t('h1')"
      />
      <PaddingComponent :padding="3.87" />

      <TwoColumnRow>
        <template v-slot:left>
          <!-- Content for the left column -->
          <p class="news-title" v-html="$t('t2')"></p>
          <br />
          <p class="news-text" v-html="$t('p2')"></p>
        </template>
        <template v-slot:right>
          <!-- Content for the right column -->
          <img
            class="news-image"
            :src="require(`@/assets/images/2023/2023 - 1.png`)"
            alt=""
          />
        </template>
      </TwoColumnRow>
    </YearEntry>
    <!-- 2023 -->

    <!-- 2048 -->
    <YearEntry id="sec-2048">
      <BackgroundImage :filename="'2048/2048 - header'" />
      <PaddingComponent :padding="32" />
      <YearComponent class="is-third" :year="'2048'" :isLarge="true" />
      <NewsletterTimestampComponent
        class="is-third"
        :date="$t('d2')"
        :place="$t('l2')"
        :time="$t('h2')"
      />
      <PaddingComponent :padding="3.87" />
      <TwoColumnRow>
        <template v-slot:left>
          <p class="news-title" v-html="$t('t3')"></p>
          <br />
          <p class="news-text" v-html="$t('p3')"></p>
        </template>
        <template v-slot:right>
          <img
            class="news-image"
            :src="require(`@/assets/images/2048/2048 - group 1.png`)"
            alt=""
          />
        </template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left>
          <img
            class="news-image"
            :src="require(`@/assets/images/2048/2048 - 3.jpg`)"
            alt=""
          />
        </template>
        <template v-slot:right>
          <p class="news-text" v-html="$t('p4')"></p>
        </template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left>
          <p class="news-text" v-html="$t('p5')"></p>
        </template>
        <template v-slot:right>
          <img
            class="news-image"
            :src="require(`@/assets/images/2048/2048 - 4.jpg`)"
            alt=""
          />
        </template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left>
          <img
            class="news-image"
            :src="require(`@/assets/images/2048/2048 - group 2.png`)"
            alt=""
          />
        </template>
        <template v-slot:right>
          <p class="news-text" v-html="$t('p6')"></p>
        </template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left>
          <p
            class="news-text is-secondary"
            style="font-weight: 500"
            v-html="$t('p7')"
          ></p
        ></template>
        <template v-slot:right>
          <img
            class="news-image"
            :src="require(`@/assets/images/2048/2048 - 8.jpg`)"
            alt=""
          />
        </template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left
          ><img
            class="news-image"
            :src="require(`@/assets/images/2048/2048 - group 3.png`)"
            alt=""
        /></template>
        <template v-slot:right>
          <p class="news-text" v-html="$t('p8')"></p>
          <br />
          <br />
          <p class="news-text is-third" v-html="$t('p9')"></p>
          <br />
          <br />
          <p class="news-text" v-html="$t('p10')"></p>
          <div></div>
        </template>
      </TwoColumnRow>
      <PaddingComponent :padding="10" />
      <div
        :style="`    
        overflow: hidden;
    position: relative;`"
      >
        <div
          :style="`overflow: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;`"
        >
          <BackgroundImage :isBlurGradient="true" :filename="'2048/2048 - 12'">
          </BackgroundImage>
        </div>

        <PaddingComponent :padding="10" />
        <div class="padded-hero">
          <div class="section">
            <div class="columns">
              <div class="column is-half-widescreen">
                <p class="news-title is-third" v-html="$t('t11')"></p>
                <br />
                <p class="news-text" v-html="$t('p11')"></p>
              </div>
              <div class="column is-half-widescreen">
                <p class="news-title is-fourth" v-html="$t('t12')"></p>
                <br />
                <p class="news-text" v-html="$t('p12')"></p>
              </div>
            </div>
          </div>
        </div>
        <PaddingComponent :padding="10" />
      </div>
      <NewsletterTimestampComponent
        class="is-third"
        :date="$t('d13')"
        :place="$t('l13')"
        :time="$t('h13')"
      />
      <TwoColumnRow>
        <template v-slot:left>
          <p class="news-title" v-html="$t('t14')"></p>
          <br />
          <p class="news-text" v-html="$t('p14')"></p>
          <br />
          <br />
          <p class="news-title is-third" v-html="$t('t15')"></p>
          <br />
          <p class="news-text" v-html="$t('p15')"></p>
        </template>
        <template v-slot:right>
          <div>
            <img
              class="news-image"
              :src="require(`@/assets/images/2048/2048 - group 4.png`)"
              alt=""
            />
          </div>
        </template>
      </TwoColumnRow>

      <PaddingComponent :padding="3" />
      <HorizontalLine />
      <PaddingComponent :padding="3" />
      <TwoColumnRow style="margin-top: 3em; margin-bottom: 3em">
        <template v-slot:left>
          <img
            class="news-image"
            :src="require(`@/assets/images/2048/2048 - 16.jpg`)"
            alt=""
          />
        </template>
        <template v-slot:right>
          <p class="is-italic" v-html="$t('p16')"></p>
        </template>
      </TwoColumnRow>
      <PaddingComponent :padding="3" />
      <HorizontalLine />
      <PaddingComponent :padding="3" />

      <TwoColumnRow>
        <template v-slot:left>
          <p class="news-text" v-html="$t('p17')"></p>
        </template>
        <template v-slot:right>
          <img
            class="news-image"
            :src="require(`@/assets/images/2048/2048 - 17.jpg`)"
            alt=""
          />
        </template>
      </TwoColumnRow>

      <PaddingComponent :padding="10" />
      <div
        :style="`    
        overflow: hidden;
    position: relative;`"
      >
        <div
          :style="`overflow: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;`"
        >
          <BackgroundImage :isBlurGradient="true" :filename="'2048/2048 - 18'">
          </BackgroundImage>
        </div>

        <PaddingComponent :padding="20" />
        <div class="section">
          <div class="columns">
            <div class="column is-four-fifths-desktop">
              <p class="news-title is-third padded-hero" v-html="$t('t18')"></p>
            </div>
          </div>
        </div>
        <PaddingComponent :padding="20" />
      </div>

      <PaddingComponent :padding="4.38" />
    </YearEntry>
    <!-- 2048 -->

    <!-- 2058 -->
    <YearEntry id="sec-2058">
      <PaddingComponent :padding="20" />

      <YearComponent class="is-third" :year="'2058'" :isLarge="false" />
      <NewsletterTimestampComponent
        class="is-third"
        :date="$t('d19')"
        :place="$t('l19')"
        :time="$t('h19')"
      />
      <!-- <BackgroundImage :filename="'2058/2058-1'"> </BackgroundImage> -->
      <img
        data-aos="fade"
        class="news-image"
        :src="require(`@/assets/images/2058/2058-1.png`)"
        alt=""
      />
      <div class="row" data-aos="fade">
        <div class="container">
          <div class="columns is-vcentered is-desktop is-variable">
            <div class="column">
              <PaddingComponent :padding="10" />
              <p
                style="padding-left: 1em; padding-right: 1em"
                class="is-italic is-primary"
                v-html="$t('p20')"
              ></p>
              <PaddingComponent :padding="10" />
            </div>
          </div>
        </div>
      </div>
    </YearEntry>
    <!-- 2058 -->

    <!-- 2073 -->
    <YearEntry id="sec-2073">
      <BackgroundImage :filename="'2073/2073 - header'" />
      <PaddingComponent :padding="32" />
      <YearComponent class="is-fourth" :year="'2073'" :isLarge="true" />
      <NewsletterTimestampComponent
        class="is-fourth"
        :date="$t('d21')"
        :place="$t('l21')"
        :time="$t('h21')"
      />
      <TwoColumnRow>
        <template v-slot:left>
          <p class="news-title is-fourth" v-html="$t('t22')"></p>
          <br /><br />

          <p class="news-text" v-html="$t('p22')"></p>
        </template>
        <template v-slot:right
          ><img
            class="news-image"
            :src="require(`@/assets/images/2073/2073 - 1.png`)"
            alt=""
        /></template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left
          ><img
            class="news-image"
            :src="require(`@/assets/images/2073/2073 - group 1.png`)"
            alt=""
        /></template>
        <template v-slot:right>
          <p class="news-text" v-html="$t('p23')"></p>
        </template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left>
          <p class="news-text" v-html="$t('p24')"></p>
        </template>
        <template v-slot:right
          ><img
            class="news-image"
            :src="require(`@/assets/images/2073/2073 - 3.png`)"
            alt=""
        /></template>
      </TwoColumnRow>

      <PaddingComponent :padding="10" />
      <div
        :style="`    
        overflow: hidden;
    position: relative;`"
      >
        <div
          :style="`overflow: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;`"
        >
          <BackgroundImage :isBlurGradient="true" :filename="'2073/2073 - 4'">
          </BackgroundImage>
        </div>

        <PaddingComponent :padding="10" />
        <div class="padded-hero">
          <div class="section">
            <p class="news-title" v-html="$t('t25')"></p>
            <br /><br />
            <p class="news-text" v-html="$t('p25')"></p>
          </div>
        </div>
        <PaddingComponent :padding="10" />
      </div>
    </YearEntry>
    <!-- 2073 -->

    <!-- 2098 -->
    <YearEntry id="sec-2098">
      <BackgroundImage :filename="'2098/2098 - header'" />
      <PaddingComponent :padding="44" />
      <YearComponent class="is-secondary" :year="'2098'" :isLarge="true" />
      <NewsletterTimestampComponent
        class="is-secondary"
        :date="$t('d26')"
        :place="$t('l26')"
        :time="$t('h26')"
      />
      <TwoColumnRow>
        <template v-slot:left>
          <p class="news-text" v-html="$t('p26')"></p>
        </template>
        <template v-slot:right
          ><img
            class="news-image"
            :src="require(`@/assets/images/2098/2098 - group 1.png`)"
            alt=""
        /></template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left
          ><img
            class="news-image"
            :src="require(`@/assets/images/2098/2098 - 4.png`)"
            alt=""
        /></template>
        <template v-slot:right>
          <p class="news-title is-third" v-html="$t('t27')"></p>
          <br />
          <br />
          <p class="news-text" v-html="$t('p27')"></p>
        </template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left
          ><p class="news-text" v-html="$t('p28')"></p
        ></template>
        <template v-slot:right
          ><img
            class="news-image"
            :src="require(`@/assets/images/2098/2098 - group 2.png`)"
            alt=""
        /></template>
      </TwoColumnRow>
      <TwoColumnRow>
        <template v-slot:left
          ><img
            class="news-image"
            :src="require(`@/assets/images/2098/2098 - 7.png`)"
            alt=""
        /></template>
        <template v-slot:right
          ><p class="news-text" v-html="$t('p29')"></p
        ></template>
      </TwoColumnRow>
      <div class="row" data-aos="fade">
        <div class="container is-fluid">
          <div class="columns is-vcentered is-desktop is-variable">
            <div class="column">
              <HorizontalLine />
              <PaddingComponent :padding="10" />
              <p class="is-italic is-fourth" v-html="$t('t30')"></p>
              <PaddingComponent :padding="10" />
              <HorizontalLine />
            </div>
          </div>
        </div>
      </div>
      <TwoColumnRow>
        <template v-slot:left
          ><p class="news-text" v-html="$t('p30')"></p
        ></template>
        <template v-slot:right
          ><img
            class="news-image"
            :src="require(`@/assets/images/2098/2098 - 8.png`)"
            alt=""
        /></template>
      </TwoColumnRow>
      <!-- <PaddingComponent :padding="7.5" /> -->
    </YearEntry>
    <!-- 2098 -->

    <!-- 2123 -->
    <YearEntry id="sec-2123">
      <BackgroundImage :filename="'2123/2123 - header'" />
      <PaddingComponent :padding="32" />
      <YearComponent class="is-third" :year="'2123'" :isLarge="true" />
      <NewsletterTimestampComponent
        class="is-third"
        :date="$t('d31')"
        :place="$t('l31')"
        :time="$t('h31')"
      />
      <TwoColumnRow>
        <template v-slot:left>
          <p class="news-text" v-html="$t('p31')"></p>
        </template>
        <template v-slot:right
          ><img
            class="news-image"
            :src="require(`@/assets/images/2123/2123 - 1.png`)"
            alt=""
        /></template>
      </TwoColumnRow>

      <div
        :style="`
    overflow: hidden;
    width: 100%;
    height: auto;
    left: 0px;
    bottom: 0px;
    position: absolute;
    `"
      >
        <BackgroundImage
          :style="`
          height: unset;
          position: relative;
          
        `"
          :isBlurGradient="true"
          :filename="'2123/2123 - 2'"
        >
        </BackgroundImage>
      </div>

      <PaddingComponent :padding="22" />

      <TwoColumnRow>
        <template v-slot:left>
          <p class="news-title is-third" v-html="$t('t32')"></p>
          <br /><br />
          <p class="news-text" v-html="$t('p32')"></p>
        </template>
      </TwoColumnRow>
      <PaddingComponent :padding="20" />
    </YearEntry>
    <!-- 2123 -->
  </div>
</template>

<script>
import TwoColumnRow from "@/components/subcomponents/TwoColumnRow.vue";
import YearComponent from "@/components/subcomponents/YearComponent.vue";
import PaddingComponent from "@/components/subcomponents/PaddingComponent.vue";
import TimestampComponent from "@/components/subcomponents/TimestampComponent.vue";
import BackgroundImage from "./subcomponents/BackgroundImage.vue";
import HeroComponent from "./subcomponents/HeroComponent.vue";
import HorizontalLine from "@/components/subcomponents/HorizontalLine.vue";
import YearEntry from "@/components/subcomponents/YearEntry.vue";
import PageHeader from "./subcomponents/PageHeader.vue";

import simpleParallax from "simple-parallax-js";

export default {
  components: {
    TwoColumnRow,
    YearComponent,
    PaddingComponent,
    NewsletterTimestampComponent: TimestampComponent,
    BackgroundImage,
    HeroComponent,
    HorizontalLine,
    YearEntry,
    PageHeader,
  },
  methods: {
    createParallax() {
      let images = document.querySelectorAll(
        ".background-image-container:not(.is-alternate)"
        // ".background-image-container"
      );
      new simpleParallax(images, {
        delay: 4,
        orientation: "up",
        scale: 1.2,
        overflow: true,
        // transition: "ease-out-quad",
        // customContainer: ".year-entry",
        // customWrapper: ".year-entry",
      });

      let images2 = document.querySelectorAll(
        ".background-image-container:is(.is-blurgradient)"
        // ".background-image-container"
      );
      new simpleParallax(images2, {
        delay: 4,
        orientation: "down",
        scale: 1,
        overflow: true,
        // transition: "ease-out-quad",
        // customContainer: ".year-entry",
        // customWrapper: ".year-entry",
      });

      // let header = document.querySelector(
      //   ".background-image-container:is(.is-alternate)"
      //   // ".background-image-container"
      // );
      // new simpleParallax(header, {
      //   delay: 4,
      //   orientation: "up",
      //   scale: 1.2,
      //   overflow: true,
      //   // customContainer: ".base",
      //   // customWrapper: ".year-entry",
      // });
    },
  },
  mounted() {
    this.createParallax();
    window.addEventListener("load", () => {
      this.createParallax();
    });
  },
};
</script>

<style lang="scss" scoped>
.news-title {
  color: $fourth;
  // @include tablet {
  //   font-size: 1.25rem;
  // }
  // @include desktop {
  //   font-size: 1.5rem;
  //   line-height: 2.2rem;
  // }
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem; /* 130% */
  white-space: pre-wrap;
}
.news-text {
  color: $primary;
  font-size: 1rem;
  line-height: 1.625rem;
  white-space: pre-wrap;
  // @include tablet {
  //   font-size: 1rem;
  // }
  // @include desktop {
  //   font-size: 1.2rem;
  //   line-height: 2rem;
  // }

  font-style: normal;
  font-weight: 400;
}
.news-image {
  width: 100%;
}

.padded-hero {
  @include from($desktop) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.is-italic {
  color: #ea9df7;
  font-family: Roboto Serif Italic;

  font-size: 1.25rem;
  line-height: 2rem;

  @include from($desktop) {
    font-size: 1.5rem;
    line-height: 2.1875rem;
  }
  font-weight: 400;
}
</style>
