<template>
  <div id="makingof" class="container is-fluid">
    <PageHeader
      data-aos="fade"
      data-aos-delay="500"
      :filename="'makingof_banner'"
      :useAlternateGradient="true"
    />
    <padding-component :padding="45" />
    <PageTitle :content="$t('n2')" />
    <horizontal-line />

    <padding-component :padding="3" />
    <p class="text" v-html="$t('mo_p1')"></p>

    <padding-component :padding="3" />
    <div class="columns is-desktop is-vcentered">
      <div class="column left">
        <Splide :options="{ rewind: true, perPage: 1 }" aria-label="Images">
          <SplideSlide>
            <img src="@/assets/images/makingof/makingof_1.png" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_13.jpg" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_1.jpg" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_7.jpg" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_15.jpg" />
          </SplideSlide>
        </Splide>
      </div>
      <div class="column right">
        <Splide
          aria-labelledby="video-example-heading"
          :options="options"
          :extensions="extensions"
        >
          <SplideSlide
            v-for="(id, index) in videos"
            :key="id"
            :data-splide-youtube="id"
          >
            <img
              :src="`https://i3.ytimg.com/vi/${id}/maxresdefault.jpg`"
              :alt="`YouTube Sample ${index + 1}`"
            />
          </SplideSlide>
        </Splide>
      </div>
    </div>

    <padding-component :padding="3" />
    <p class="text" v-html="$t('mo_p2')"></p>

    <padding-component :padding="3" />

    <div class="columns is-desktop is-vcentered">
      <div class="column left">
        <Splide :options="{ rewind: true, perPage: 1 }" aria-label="Images">
          <SplideSlide>
            <img src="@/assets/images/makingof/makingof_3.png" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_2.jpg" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_5.jpg" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_6.jpg" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_3.jpg" />
          </SplideSlide>
        </Splide>
      </div>
      <div class="column right">
        <Splide :options="{ rewind: true, perPage: 1 }" aria-label="Images">
          <SplideSlide>
            <img src="@/assets/images/makingof/makingof_4.png" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_12.jpg" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_4.jpg" />
          </SplideSlide>
          <SplideSlide>
            <img src="@/assets/images/makingof/making_of_10.jpg" />
          </SplideSlide>
        </Splide>
      </div>
    </div>

    <padding-component :padding="3" />
    <p class="text" v-html="$t('mo_p3')"></p>

    <padding-component :padding="15" />
    <PageTitle content="Proje Ekibi" />
    <horizontal-line />

    <padding-component :padding="2" />
    <two-column-row :isVCentered="false" class="credits">
      <template v-slot:left>
        <p class="subtitle">{{ $t(`mo_c_t_1`) }}</p>
        <div v-for="index in 9" :key="index">
          <div class="text">
            <b>{{ $t(`mo_c_st_${index}`) }}</b
            ><br />{{ $t(`mo_c_p_${index}`) }}
          </div>
          <br />
        </div>
      </template>
      <template v-slot:right>
        <p class="subtitle">{{ $t(`mo_c2_t_2`) }}</p>
        <div v-for="index in 20" :key="index">
          <div class="text">
            <b>{{ $t(`mo_c2_st_${index}`) }}</b
            ><br />{{ $t(`mo_c2_p_${index}`) }}
          </div>
          <br />
        </div>
      </template>
    </two-column-row>
  </div>
</template>

<script>
import PageTitle from "@/components/subcomponents/PageTitle.vue";
import PaddingComponent from "./subcomponents/PaddingComponent.vue";
import HorizontalLine from "./subcomponents/HorizontalLine.vue";
import TwoColumnRow from "./subcomponents/TwoColumnRow.vue";
import PageHeader from "./subcomponents/PageHeader.vue";

import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";
import { Video } from "@splidejs/splide-extension-video";

export default {
  components: {
    PageTitle,
    PaddingComponent,
    HorizontalLine,
    TwoColumnRow,
    PageHeader,
    Splide,
    SplideSlide,
  },
  setup() {
    const videos = ["I-5mukdtcNc", "-QXiUTLrJBo", "HZ7DDx9d8No", "Q0kssIFq4DQ"];

    const options = {
      rewind: true,
      heightRatio: 0.5625,
    };

    return {
      videos,
      options,
      extensions: { Video },
    };
  },
};
</script>

<style lang="scss">
html {
  background: #0d2e5d !important;
}
</style>

<style lang="scss" scoped>
.title {
  color: #fef7c2;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem;
  /* 106.25% */
}

.subtitle {
  color: $secondary;
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem;
  /* 212.5% */
}

.text {
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  white-space: pre-wrap;
  /* 175% */
}

.text {
  @include until($desktop) {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.credits .text {
  white-space: pre-wrap;

  font-weight: 300;
}
</style>
