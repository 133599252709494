import timeline from "@/locales/timeline.json";
import methodology from "@/locales/methodology.json";
import makingof from "@/locales/makingof.json";
import contribute from "@/locales/contribute.json";

const languages = ["en", "tr"];
const pages = [timeline, methodology, makingof, contribute];

const mergedTranslations = {};

languages.forEach((language) => {
  mergedTranslations[language] = {};

  pages.forEach((translationObject) => {
    mergedTranslations[language] = {
      ...mergedTranslations[language],
      ...translationObject[language],
    };
  });
});

export default mergedTranslations;
