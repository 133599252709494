<template>
  <div id="methodology" class="container is-fluid" :lang="this.$i18n.locale">
    <PageHeader
      data-aos="fade"
      data-aos-delay="500"
      :filename="'methodology_banner'"
      :useAlternateGradient="true"
    />
    <padding-component :padding="45" />
    <PageTitle :content="$t('n1')" />
    <horizontal-line />

    <padding-component :padding="3" />
    <p v-html="$t('m_p1')"></p>

    <padding-component :padding="3" />

    <two-column-row :isVCentered="false">
      <template v-slot:left>
        <h3 class="subtitle" v-html="$t('m_t2')"></h3>
        <p class="text" v-html="$t('m_p2')"></p>
      </template>
      <template v-slot:right>
        <div class="videowrapper">
          <iframe
            src="https://www.youtube.com/embed/KfA6fcuE_mo?si=rxWjupdbrmlysYV8&autoplay=1&loop=1&playlist=KfA6fcuE_mo"
            frameborder="0"
            allow="autoplay;"
            allowfullscreen
          ></iframe>
        </div>
      </template>
    </two-column-row>

    <two-column-row :isVCentered="false">
      <template v-slot:left>
        <img src="@/assets/images/makingof/making_of_9.jpg" />
      </template>
      <template v-slot:right>
        <h3 class="subtitle" v-html="$t('m_t3')"></h3>
        <p class="text" v-html="$t('m_p3')"></p>
      </template>
    </two-column-row>

    <two-column-row :isVCentered="false">
      <template v-slot:left>
        <h3 class="subtitle" v-html="$t('m_t4')"></h3>
        <p class="text" v-html="$t('m_p4')"></p>
      </template>
      <template v-slot:right>
        <img src="@/assets/images/makingof/making_of_11.jpg" />
      </template>
    </two-column-row>
    <two-column-row :isVCentered="false">
      <template v-slot:left>
        <p class="text" v-html="$t('m_p4_2')"></p>
      </template>
      <template v-slot:right>
        <img src="@/assets/images/makingof/kaiber.jpg" />
      </template>
    </two-column-row>

    <two-column-row :isVCentered="false">
      <template v-slot:left>
        <img src="@/assets/images/makingof/hemsire_color.jpg" />
      </template>
      <template v-slot:right>
        <p class="text" v-html="$t('m_p4_3')"></p>
        <p class="text" v-html="$t('m_p4_4')"></p>
      </template>
    </two-column-row>

    <two-column-row :isVCentered="false">
      <template v-slot:left>
        <p class="subtitle" v-html="$t('m_t7')"></p>
        <p class="text" v-html="$t('m_p7')"></p>
      </template>
      <template v-slot:right>
        <img src="@/assets/images/makingof/making_of_8.jpg" />
      </template>
    </two-column-row>

    <two-column-row :isVCentered="false">
      <template v-slot:left>
        <h3 class="subtitle" v-html="$t('m_t8')"></h3>
        <p class="text" v-html="$t('m_p8')"></p>
      </template>
      <template v-slot:right>
        <img src="@/assets/images/makingof/making_of_16.jpg" />
      </template>
    </two-column-row>

    <two-column-row :isVCentered="false">
      <template v-slot:left>
        <img src="@/assets/images/makingof/making_of_17.jpg" />
      </template>

      <template v-slot:right>
        <h3 class="subtitle" v-html="$t('m_t6')"></h3>
        <p class="text" v-html="$t('m_p6')"></p>
      </template>
    </two-column-row>

    <two-column-row :isVCentered="false">
      <template v-slot:left>
        <h3 class="subtitle" v-html="$t('m_t5')"></h3>
        <p class="text" v-html="$t('m_p5')"></p>
      </template>
      <template v-slot:right>
        <img src="@/assets/images/makingof/makingof_3.png" />
      </template>
    </two-column-row>

    <PaddingComponent :padding="10" />
  </div>
</template>

<script>
import PageTitle from "@/components/subcomponents/PageTitle.vue";
import PaddingComponent from "./subcomponents/PaddingComponent.vue";
import HorizontalLine from "./subcomponents/HorizontalLine.vue";
import TwoColumnRow from "./subcomponents/TwoColumnRow.vue";
import PageHeader from "./subcomponents/PageHeader.vue";
// import { Video } from '@splidejs/splide-extension-video';
// import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';

export default {
  components: {
    PageTitle,
    PaddingComponent,
    HorizontalLine,
    TwoColumnRow,
    PageHeader,
  },
};
</script>

<style lang="scss">
html {
  background: $background2 !important;
}
</style>

<style lang="scss" scoped>
.title {
  color: #fef7c2;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem;
  /* 106.25% */
}

.subtitle {
  color: $secondary;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem;
  text-transform: uppercase;

  /* 212.5% */
}

.text {
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  white-space: pre-wrap;

  /* 175% */
}

img {
  justify-content: center;
  display: flex;
  margin: auto;
}

.videowrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
