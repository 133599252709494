<template>
  <div class="year-entry">
    <!-- <PaddingComponent :padding="0" /> -->

    <HorizontalLine :opacity="0.5" v-if="showTopLine" />

    <slot></slot>

    <PaddingComponent :padding="5" />
    <!-- <HorizontalLine :opacity="0.2" v-if="showBottomLine" /> -->
  </div>
</template>

<script>
import PaddingComponent from "@/components/subcomponents/PaddingComponent.vue";
import HorizontalLine from "@/components/subcomponents/HorizontalLine.vue";

export default {
  components: {
    PaddingComponent,
    HorizontalLine,
  },
  props: {
    showTopLine: {
      type: Boolean,
      default: true,
    },
    showBottomLine: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.year-entry {
  display: unset;
}
</style>
