<template>
  <div class="row">
    <div class="container is-fluid">
      <div
        class="columns is-desktop is-variable"
        :class="isVCentered ? 'is-vcentered' : ''"
      >
        <div
          :class="{ active: $slots.left }"
          data-aos="fade"
          data-aos-duration="400"
          data-aos-delay="400"
          class="column left"
        >
          <slot name="left"></slot>
        </div>
        <div
          :class="{ active: $slots.right }"
          data-aos="fade"
          data-aos-duration="400"
          data-aos-delay="400"
          class="column right"
        >
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVCentered: {
      type: Boolean,
      default: true,
    },
  },
  name: "TwoColumnRow",
};
</script>

<style lang="scss" scoped>
.row {
  @include until($desktop) {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}
.columns.is-variable {
  @include from($desktop) {
    --columnGap: 5rem;
  }

  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.column {
  @include until($tablet) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.column:not(.active) {
  padding-top: 0;
  padding-bottom: 0;
  @include from($desktop) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.column.left {
  padding-left: 0 !important;

  @include until($tablet) {
    padding-right: 0 !important;
  }
}

.column.right {
  padding-right: 0 !important;

  @include until($tablet) {
    padding-left: 0 !important;
  }
}
</style>
