<template>
  <div class="navbar-item" :class="`align-${alignment}`">
    <router-link :to="link" class="link">
      <slot></slot>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    alignment: { type: String, default: "auto" },
    link: { type: String, default: "#" },
  },
};
</script>

<style lang="scss">
.navbar-item {
  color: $secondary !important;
  padding: 1rem 0rem !important;
  font-family: $family-monospace;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  flex: 1;
  justify-content: center;

  img {
    max-height: 7.75rem !important;
    min-width: 4rem !important;
  }
}

.link {
  background-image: linear-gradient($primary, $primary),
    linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  background-size: 0 1.5px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}

.link:hover,
.link.router-link-active {
  color: $primary !important;
  background-size: 100% 1.5px, auto;
}

.navbar-item a {
  color: inherit !important;
  text-decoration: none !important;
}

.align-left {
  justify-content: left;
}

.align-right {
  justify-content: right;
}

.align-center {
  flex: 1;
  justify-content: center;
}

.align-auto {
  flex: auto;
}
</style>
