<template>
  <div data-aos="fade" class="title">
    {{ content }}
  </div>
</template>

<script>
export default {
    props: {
        content: {
            type: String
        },
    },
}
</script>

<style lang="scss" scoped>
    .title {
        color: #FEF7C2;
        font-family: Roboto;
        font-size: 2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.125rem; /* 106.25% */
    }
</style>