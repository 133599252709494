<template>
  <div>
    <label class="label">
        {{label}}:
    </label>
  <div class="control">
    <slot></slot>
  </div>
  </div>
</template>

<script>
export default {
    props: {
        "label": {
            type: String,
            default: "",
            required: true,
        }

    }
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
    .control {
        border: 0.5px solid rgba(255, 255, 255, 0.50);
        opacity: 0.8;
        backdrop-filter: blur(3px);
    }
    .label {
        color: #FFF;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem; /* 175% */
    }

</style>