<template>
  <nav
    class="navbar is-fixed-top"
    :class="{ hidden: !showNavbar }"
    id="navbar"
    ref="navbar"
  >
    <div
      v-if="showNavbar"
      :class="['is-fit', 'blur']"
      :style="[
        { backdropFilter: `blur(${blurAmount}px` },
        {
          borderBottom: `1px solid rgba(255, 255, 255, ${
            (blurAmount / maxBlur) * 0.25
          })`,
        },
        {
          WebkitBoxShadow: `rgba(0, 0, 0, ${
            (blurAmount / maxBlur) * 0.25
          }) 0px 1px 3px 3px`,
        },
      ]"
      style="position: absolute"
    ></div>
    <div
      class="container is-fluid"
      style="
        display: inline-flex;
        overflow-x: auto;
        column-gap: 2rem;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
      "
    >
      <NavbarItem :alignment="'left'" :link="'/methodology'">{{
        $t("n1")
      }}</NavbarItem>
      <NavbarItem :alignment="'left'" :link="'/makingof'">{{
        $t("n2")
      }}</NavbarItem>
      <NavbarTimelineBadge :isActive="true" :link="'/'" />
      <NavbarItem :alignment="'right'" :link="'/postcards'">{{
        $t("n3")
      }}</NavbarItem>
      <NavbarItem :alignment="'right'" :link="'/contribute'">{{
        $t("n4")
      }}</NavbarItem>
      <div class="locale-changer">
        <a
          href="javascript:void(0);"
          @click="changeLanguage('tr')"
          :class="{ selected: currentLanguage === 'tr' }"
          >TR</a
        >
        |
        <a
          href="javascript:void(0);"
          @click="changeLanguage('en')"
          :class="{ selected: currentLanguage === 'en' }"
          >EN</a
        >
      </div>
    </div>
  </nav>
</template>

<script>
/* eslint-disable */
window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

import NavbarItem from "@/components/subcomponents/NavbarItem.vue";
import NavbarTimelineBadge from "@/components/subcomponents/NavbarTimelineBadge.vue";
import { ref, onMounted, computed } from "vue";

function remap(value, inputMin, inputMax, outputMin, outputMax) {
  // Ensure the value is within the input range
  value = Math.max(inputMin, Math.min(inputMax, value));

  // Calculate the remapped value
  const inputRange = inputMax - inputMin;
  const outputRange = outputMax - outputMin;
  return ((value - inputMin) / inputRange) * outputRange + outputMin;
}

export default {
  setup() {
    const scrollPosition = ref(0);
    const showNavbar = ref(true);
    const lastScrollPosition = ref(0);
    const accumulate = ref(0);
    const navbarHideThreshold = window.innerHeight / 4;
    const hideOnScroll = window.mobileAndTabletCheck();

    onMounted(() => {
      window.addEventListener("scroll", () => {
        scrollPosition.value = window.scrollY;

        if (hideOnScroll) {
          const diff = scrollPosition.value - lastScrollPosition.value;
          if (diff > 0) {
            accumulate.value += diff;
            if (accumulate.value >= navbarHideThreshold) {
              showNavbar.value = false;
              accumulate.value = 0;
            }
          } else {
            accumulate.value = 0;
            showNavbar.value = true;
          }

          // Set the current scroll position as the last scroll position
          lastScrollPosition.value = scrollPosition.value;
        }
      });
    });

    const minThreshold = 50;
    const maxThreshold = 200;
    const maxBlur = 4;

    // Define a computed property for blur amount based on scroll position
    const blurAmount = computed(() => {
      // Calculate the blur amount based on scroll position
      if (scrollPosition.value <= minThreshold) {
        return 0; // No blur initially
      } else if (scrollPosition.value >= maxThreshold) {
        return maxBlur; // Maximum blur
      } else {
        return remap(
          scrollPosition.value,
          minThreshold,
          maxThreshold,
          0,
          maxBlur
        ); // Increase blur gradually
      }
    });

    return {
      scrollPosition,
      showNavbar,
      lastScrollPosition,
      blurAmount,
      maxBlur,
    };
  },
  components: {
    NavbarItem,
    NavbarTimelineBadge,
  },
  computed: {
    currentLanguage() {
      // Assuming you have a way to get the current language, e.g., using Vue I18n
      return this.$i18n.locale;
    },
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
    },
  },
};
</script>

<style lang="scss">
.is-fit {
  width: 100%;
  height: 100%;
}

.blur {
  background-color: rgba(0, 0, 0, 0) !important;
  // -webkit-backdrop-filter: blur(8px);
  // backdrop-filter: blur(8px);
}

#navbar {
  background: none;
  padding: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s all cubic-bezier(0.22, 1, 0.36, 1);
}

#navbar.hidden {
  // box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

#navbar .container {
  justify-content: center;
}

#navbar .navbar-item {
  flex-grow: 1;
}

.locale-changer,
.locale-changer a {
  color: $secondary;
  text-align: center;
  font-family: $family-monospace;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5625rem; /* 208.333% */

  .selected {
    color: $white;
  }
}

.locale-changer {
  position: unset;
  @include from($tablet) {
    position: absolute;
  }

  right: 5rem;
  top: 1rem;
}
</style>
