<template>
  <div class="row">
    <div
      data-aos="fade"
      data-aos-duration="400"
      data-aos-delay="400"
      class="columns is-vcentered is-desktop is-variable"
      :class="{ 'is-reversed': isFlipped }"
    >
      <div class="column is-half">
        <div class="date">{{ formattedDate }}</div>
        <div class="text">{{ text }}</div>
        <div class="author">{{ author }}</div>
      </div>
      <div class="column is-half right">
        <!-- <img :src="require(`@/assets/images/postcards/${imagePath}`)"> -->
        <img :src="`http://ku30.com.tr/media/image/full.php?id=${imagePath}`" />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import HTTPRequester from "@/models/HTTPRequester";

export default {
  name: "PostcardComponent",
  props: {
    author: { type: String },
    date: { type: Date },
    text: { type: String },
    imagePath: { type: String },
    isFlipped: { type: Boolean },
  },
  computed: {
    formattedDate() {
      if (!this.date) {
        return "--/--/----";
      }
      const day = this.date.getDate().toString().padStart(2, "0");
      const month = (this.date.getMonth() + 1).toString().padStart(2, "0");
      const year = this.date.getFullYear().toString();

      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.columns.is-variable {
  @include from($tablet) {
    --columnGap: 5rem;
  }
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 0;
  margin-bottom: 5rem;
  //   max-height: 26rem;
  flex-wrap: wrap;
  align-items: flex-start;
}
.column {
  padding-top: 0;
  padding-bottom: 0;

  img {
    max-height: 25rem;
  }
  margin: auto;
}
.column.right {
  display: flex !important;
  justify-content: center;
}

.text {
  white-space: pre-wrap;
  color: $primary;
  font-family: $family-italic;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.75rem; /* 175% */
}
.date,
.author {
  color: $primary;
  text-align: right;
  font-family: $family-primary;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 200% */
}
.is-reversed {
  flex-direction: row-reverse; /* Reverse the order of columns */
}
</style>
