<template>
  <div id="contribute" class="container is-fluid">
    <PageHeader
      data-aos="fade"
      data-aos-once="true"
      data-aos-delay="500"
      :filename="'contribute_banner'"
      :useAlternateGradient="true"
    />
    <padding-component :padding="45" />
    <PageTitle :content="$t('n4')" />
    <horizontal-line />

    <Transition>
      <div v-if="!form1_complete">
        <padding-component :padding="3" />
        <p class="subtitle">
          {{ $t(`c_t1`) }}
        </p>
        <p>
          {{ $t(`c_p1`) }}
        </p>
        <padding-component :padding="3.75" />

        <Form
          class="form"
          ref="form1"
          @submit="submitForm1"
          :validation-schema="schema1"
        >
          <div class="columns">
            <div class="column is-half-desktop">
              <form-item :label="$t(`c_f1`)">
                <Field name="f1_name" type="text" class="input" />
              </form-item>
              <ErrorMessage class="error_message" name="f1_name" />
            </div>
            <div class="column is-half-desktop">
              <form-item :label="$t(`c_f2`)">
                <div class="select">
                  <!-- eslint-disable-next-line -->
                  <Field name="f1_date" as="select">
                    <option disabled selected value="">-</option>
                    <option value="2048">2048</option>
                    <option value="2053">2053</option>
                    <option value="2073">2073</option>
                    <option value="2098">2098</option>
                    <option value="2123">2123</option>
                  </Field>
                  <ErrorMessage class="error_message" name="f1_date" />
                </div>
              </form-item>
            </div>
          </div>

          <div class="columns">
            <!-- <div class="column is-half-desktop"></div> -->
            <div class="column is-offset-half-desktop is-half-desktop">
              <form-item :label="$t(`c_f3`)">
                <Field name="f1_email" type="email" class="input" />
              </form-item>
              <ErrorMessage class="error_message" name="f1_email" />
            </div>
          </div>

          <padding-component :padding="3" />
          <form-item :label="$t(`c_f4`)">
            <Field
              name="f1_text"
              class="textarea italic"
              as="textarea"
              aria-multiline="true"
            />
          </form-item>
          <ErrorMessage class="error_message" name="f1_text" />

          <padding-component :padding="6" />
          <button type="submit" class="send" ref="f1_send_button">
            {{ $t(`c_send`) }}
          </button>
        </Form>
        <padding-component :padding="5" />
      </div>
    </Transition>
    <Transition>
      <div v-if="form1_complete">
        <PaddingComponent :padding="5"></PaddingComponent>
        <p class="subtitle">{{ $t(`c_success`) }}</p>
        <PaddingComponent :padding="5"></PaddingComponent>
      </div>
    </Transition>

    <HorizontalLine />
    <padding-component :padding="5" />

    <Transition>
      <div v-if="!form2_complete">
        <p class="subtitle">
          {{ $t(`c_t2`) }}
        </p>
        <p>
          {{ $t(`c_p2`) }}
        </p>
        <padding-component :padding="3.75" />

        <Form
          class="form"
          :validation-schema="schema2"
          ref="form2"
          @submit="submitForm2"
        >
          <div class="columns">
            <!-- <div class="column is-half-desktop"></div> -->
            <div class="column is-offset-half-desktop is-half-desktop">
              <form-item :label="$t(`c_f5`)">
                <Field
                  as="textarea"
                  name="f2_date"
                  type="text"
                  class="input"
                  id="dateInput"
                  aria-multiline="true"
                  :placeholder="$t(`c_dateplaceholder`)"
                />
              </form-item>
              <ErrorMessage class="error_message" name="f2_date" />
            </div>
          </div>
          <padding-component :padding="3" />
          <form-item :label="$t(`c_f6`)">
            <Field as="textarea" class="textarea italic" name="f2_text" />
          </form-item>
          <ErrorMessage class="error_message" name="f2_text" />

          <padding-component :padding="3.75" />
          <form-item :label="$t(`c_f7`)">
            <div class="dropzone" id="image-upload" ref="f2_image"></div>
            <p>{{ image_upload_error_message }}</p>
          </form-item>

          <padding-component :padding="3" />
          <div class="columns">
            <!-- <div class="column is-half-desktop"></div> -->
            <div class="column is-offset-half-desktop is-half-desktop">
              <form-item :label="$t(`c_f3`)">
                <Field type="email" class="input" name="f2_email" />
              </form-item>
              <ErrorMessage class="error_message" name="f2_email" />
            </div>
          </div>
          <div class="columns">
            <!-- <div class="column is-half-desktop"></div> -->
            <div class="column is-offset-half-desktop is-half-desktop">
              <form-item :label="$t(`c_f1`)">
                <Field type="text" class="input" name="f2_name" />
              </form-item>
              <ErrorMessage class="error_message" name="f2_name" />
            </div>
          </div>

          <padding-component :padding="6" />
          <div class="row">
            <button :style="''" type="submit" class="send" ref="f2_send_button">
              {{ $t(`c_send`) }}
            </button>
          </div>
        </Form>
      </div>
    </Transition>
    <Transition>
      <div v-if="form2_complete">
        <PaddingComponent :padding="5"></PaddingComponent>
        <p class="subtitle">{{ $t(`c_success`) }}</p>
        <PaddingComponent :padding="5"></PaddingComponent>
      </div>
    </Transition>
    <PaddingComponent :padding="10"></PaddingComponent>
  </div>
</template>

<script>
import PageTitle from "@/components/subcomponents/PageTitle.vue";
import PaddingComponent from "./subcomponents/PaddingComponent.vue";
import HorizontalLine from "./subcomponents/HorizontalLine.vue";
import PageHeader from "./subcomponents/PageHeader.vue";
import FormItem from "./subcomponents/FormItem.vue";

import Dropzone from "dropzone";
import { Form, Field, ErrorMessage } from "vee-validate";

import * as yup from "yup";
import { ref } from "vue";
import HTTPRequester from "@/models/HTTPRequester";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const form1 = ref(null);
    const form2 = ref(null);

    const f1_send_button = ref(null);
    const f2_send_button = ref(null);

    const image_dropzone = ref(null);

    const { t } = useI18n({ useScope: "global" });

    const getSchema1 = (_t) => {
      return yup.object({
        f1_email: yup
          .string()
          .required(_t("required_text"))
          .email(_t("invalid_email")),
        f1_name: yup
          .string()
          .required(_t("required_text"))
          .min(3, _t("min_characters", { count: 3 })),
        f1_text: yup
          .string()
          .required(_t("required_text"))
          .min(3, _t("min_characters", { count: 3 }))
          .max(3000, _t("max_characters", { count: 3000 })),
        f1_date: yup.string().required(_t("required_text")).min(1),
      });
    };
    const getSchema2 = (_t) => {
      return yup.object({
        f2_email: yup
          .string()
          .required(_t("required_text"))
          .email(_t("invalid_email")),
        f2_name: yup
          .string()
          .required(_t("required_text"))
          .min(3, _t("min_characters", { count: 3 })),
        f2_text: yup
          .string()
          .required(_t("required_text"))
          .min(3, _t("min_characters", { count: 3 }))
          .max(3000, _t("max_characters", { count: 3000 })),
        f2_date: yup
          .string()
          .required(_t("required_text"))
          .min(3, _t("min_characters", { count: 3 }))
          .matches(
            /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(202[3-9]|20[3-9][0-9]|21[0-9][0-9]|2200)$/,
            _t("date_format_invalid")
          ),
      });
    };
    let schema1 = getSchema1(t);
    let schema2 = getSchema2(t);

    return {
      t,
      getSchema1,
      getSchema2,
      schema1,
      schema2,

      form1,
      form2,
      f1_send_button,
      f2_send_button,
      image_dropzone,
    };
  },
  data() {
    return {
      form1_complete: false,
      form2_complete: false,
      image_upload_success_resp: "",
      image_upload_error_message: "",
      latest_file_id: null,

      // dictDefaultMessage: this.$t("c_f8"),
      // dictFallbackMessage:
      //   "Tarayıcınız sürükle-bırak dosya yüklemelerini desteklemiyor.",
      // dictFallbackText:
      //   "Lütfen dosyanızı eski yöntemle yüklemek için aşağıdaki formu kullanın.",
      // dictFileTooBig:
      //   "Dosya çok büyük ({{filesize}}MiB). Maksimum dosya boyutu: {{maxFilesize}}MiB.",
      // dictInvalidFileType: "Bu tür dosyaları yükleyemezsiniz.",
      // dictResponseError: "Sunucu, {{statusCode}} kodu ile yanıt verdi.",
      // dictCancelUpload: "Yüklemeyi İptal Et",
      // dictCancelUploadConfirmation:
      //   "Bu yüklemeyi iptal etmek istediğinizden emin misiniz?",
      // dictRemoveFile: "Dosyayı Kaldır",
      // dictMaxFilesExceeded: "Daha fazla dosya yükleyemezsiniz.",
    };
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      console.log("locale change", oldVal, newVal);
      this.schema1 = this.getSchema1(this.t);
      this.schema2 = this.getSchema2(this.t);
    },
  },
  mounted() {
    this.image_dropzone = new Dropzone("#image-upload", {
      url: HTTPRequester.get_request_endpoint_url(
        HTTPRequester.endpoints.image_upload
      ),
      maxFilesize: 20, // MB,
      uploadMultiple: false,
      maxFiles: 1,
      acceptFiles: "image/jpeg,image/png,image/jpg",
      acceptedMimeTypes: "image/jpeg,image/png,image/jpg",
      dictDefaultMessage:
        "Dosyanızı yüklemek için buraya sürükleyin veya tıklayın",
      dictFallbackMessage:
        "Tarayıcınız sürükle-bırak dosya yüklemelerini desteklemiyor.",
      dictFallbackText:
        "Lütfen dosyalarınızı eski yöntemle yüklemek için aşağıdaki formu kullanın.",
      dictFileTooBig:
        "Dosya çok büyük ({{filesize}}MiB). Maksimum dosya boyutu: {{maxFilesize}}MiB.",
      dictInvalidFileType: "Bu tür dosyaları yükleyemezsiniz.",
      dictResponseError: "Sunucu, {{statusCode}} kodu ile yanıt verdi.",
      dictCancelUpload: "Yüklemeyi İptal Et",
      dictCancelUploadConfirmation:
        "Bu yüklemeyi iptal etmek istediğinizden emin misiniz?",
      dictRemoveFile: "Dosyayı Kaldır",
      dictMaxFilesExceeded: "Daha fazla dosya yükleyemezsiniz.",
    });

    this.image_dropzone.on("addedfile", (file) => {
      console.log(`File added: ${file.name}`);
    });
    this.image_dropzone.on("success", (file, response) => {
      this.image_upload_error_message = "";
      // console.log(response);
      if (response) {
        response = JSON.parse(response);
        console.log(`File succesfully uploaded: ${file.name}`);
        if (response["fileId"]) this.latest_file_id = response["fileId"];
      }
    });
    this.image_dropzone.on("error", (file, response) => {
      this.image_upload_error_message = response;
      // console.log(response);
      console.log(`File failed to upload: ${file.name}`);
    });
  },
  methods: {
    // eslint-disable-next-line
    submitForm1(vals) {
      this.$refs.f1_send_button.disabled = true;

      HTTPRequester.request(HTTPRequester.endpoints.user_comment_add, "post", {
        param: {
          year: vals.f1_date,
          fullname: vals.f1_name,
          email: vals.f1_email,
          infoTR: vals.f1_text,
        },
        callback: (response) => {
          if (response.status === 1) this.form1_complete = true;
        },
      });
    },
    // eslint-disable-next-line
    submitForm2(vals) {
      // console.log(vals);
      this.$refs.f2_send_button.disabled = true;

      const acceptedFiles = this.image_dropzone.getAcceptedFiles();
      if (acceptedFiles.length == 1) {
        const files = acceptedFiles.filter((x) => x.status !== "error");
        if (files.length) {
          if (this.latest_file_id == null) {
            console.log("Latest File ID was not assigned properly!");
            return;
          }
          HTTPRequester.request(
            HTTPRequester.endpoints.user_postcard_add,
            "post",
            {
              param: {
                imgId: this.latest_file_id,
                date: vals.f2_date.split("/").reverse().join("/"),
                fullname: vals.f2_name,
                email: vals.f2_email,
                infoTR: vals.f2_text,
              },
              callback: (response) => {
                if (response.status === 1) this.form2_complete = true;
              },
            }
          );
        }
      }
    },
  },
  components: {
    PageTitle,
    PaddingComponent,
    HorizontalLine,
    PageHeader,
    FormItem,
    Form,
    Field,
    ErrorMessage,
  },
};
</script>

<style lang="scss">
html {
  background: #002b45 !important;
}
</style>

<style lang="scss" scoped>
#image-upload {
  // height: 100px;
}

.title {
  color: #fef7c2;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem;
  /* 106.25% */
}

.subtitle {
  color: $secondary;
  font-family: Roboto Serif Italic;
  font-size: 1.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 2.625rem;
  /* 140% */
}

.text {
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  /* 175% */
}

.textarea,
.input {
  color: $primary;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.textarea {
  padding: 2rem;
}

.italic {
  font-family: Roboto Serif Italic;
  font-style: italic;
}

.select {
  width: 100%;
  border: none;
  border-radius: 0;

  select {
    border-radius: 0;
    width: 100%;
    background: none;
    border: none;
    color: $primary;
  }
}

option {
  border: 1px solid #fff;
  background-color: transparent;
  backdrop-filter: blur(4px);
  background-color: $background;
}

.send {
  color: #fef7c2;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2.125rem;
  /* 212.5% */
  text-decoration-line: underline;
  float: right;
}

.error_message {
  color: $secondary;
  font-style: italic;
  position: absolute;
}

.dropzone {
  text-align: center;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
