import axios from "axios";

export default class HTTPRequester {
  static BASE_URL = "http://ku30.com.tr/";

  static endpoints = {
    user_comment_add: "/data/user.comment.add.php",
    user_postcard_add: "/data/user.postcard.add.php",
    image_upload: "/data/img.upload.php",
    postcard_list: "/data/user.postcard.list.php",
  };

  static get_request_endpoint_url(request) {
    // let endpoint = this.endpoints[request];
    // if (!endpoint) {
    //   throw new Error("Invalid endpoint.");
    // }

    return new URL(request, this.BASE_URL).href;
  }

  static request_preprocess_fn = {};

  static request_postprocess_fn = {};

  static async request(
    request,
    method = "post",
    { param = null, callback = null }
  ) {
    const endpoint_url = this.get_request_endpoint_url(request);

    // const preprocess_fn = this.request_preprocess_fn[request];
    // param = preprocess_fn ? preprocess_fn(param) : param;

    let { data } = await axios({
      method: method,
      url: endpoint_url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: param,
    });

    const postprocess_fn = this.request_postprocess_fn[request];
    data = postprocess_fn ? postprocess_fn(data) : data;

    callback?.(data);
  }
}
