<template>
  <div
    class="background-image-container"
    :class="[
      { 'is-alternate': useAlternateGradient },
      { 'is-blurgradient': isBlurGradient },
      { 'is-faded': isFaded },
    ]"
  >
    <img
      class="background-image"
      :src="require(`@/assets/images/${filename}.png`)"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    filename: { type: String },
    isBlurGradient: { type: Boolean, default: false },
    useAlternateGradient: { type: Boolean, default: false },
    isFaded: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss">
.background-image-container {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: -1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: inherit;
}

.background-image {
  height: auto;
  min-width: fit-content;
  // max-width: 100%;
  width: 100%;
  display: block;
  z-index: 1;
}

.background-image-container::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  mix-blend-mode: normal;
  z-index: 2;
}

.is-alternate::before {
  background: linear-gradient(
    352deg,
    #002b45 32.38%,
    rgba(4, 37, 103, 0) 74.47%
  );
}
.is-faded::before {
  background: linear-gradient(
    351deg,
    #002b45 31.86%,
    rgba(4, 37, 103, 0) 58.79%,
    #002b45 84.67%
  );
}
.is-blurgradient::before {
  background: linear-gradient(
    105deg,
    rgba(0, 43, 69, 0.8) 0%,
    rgba(4, 37, 103, 0.2) 100%
  );
  backdrop-filter: blur(3px);
}
</style>
