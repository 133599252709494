<template>
  <div ref="app">
    <div>
      <div class="loader-wrapper" :class="{ 'is-active': !showApp }">
        <div class="loader is-loading"></div>
      </div>
    </div>
    <div :style="{ visibility: showApp ? 'visible' : 'hidden' }">
      <div class="base">
        <NavbarComponent />
        <VerticalLines />

        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import router from "./router"; // Import the router instance

import NavbarComponent from "@/components/subcomponents/NavbarComponent.vue";
import VerticalLines from "@/components/subcomponents/VerticalLines.vue";
import { ref } from "vue";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "App",
  components: {
    NavbarComponent,
    VerticalLines,
  },
  setup() {
    const currentPath = ref(window.location.pathname);

    // Update currentPath when the route changes
    router.afterEach((to) => {
      currentPath.value = to.path;
    });

    return {
      currentPath,
    };
  },
  data() {
    return {
      showApp: false,
    };
  },
  methods: {},
  mounted() {
    window.addEventListener("load", () => {
      // run after everything is in-place
      this.showApp = true;

      AOS.init({
        offset: 200,
        duration: 750,
        easing: "ease-out-quad",
        delay: 50,
      });
    });
  },
  unmounted() {},
};
</script>

<style lang="scss">
#app {
  font-family: $family-primary, $family-secondary !important;
  color: $primary;
}

p a {
  color: inherit !important;
  text-decoration: underline !important;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  pointer-events: none;
  z-index: 10;

  .loader {
    height: 80px;
    width: 80px;
  }
  &.is-active {
    opacity: 1;
    // z-index: 1;
  }
}
</style>
