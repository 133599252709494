<template>
  <div class="vertical-lines middle"></div>
  <div class="vertical-lines side"></div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.vertical-lines {
  // position: absolute;
  position: fixed;
  pointer-events: none;
  height: 120%;

  &.side {
    border-left: rgba(255, 255, 255, 0.5) 1px solid;
    border-right: rgba(255, 255, 255, 0.5) 1px solid;
    left: 1.88rem;
    right: 1.88rem;
  }
  &.middle {
    border-left: rgba(255, 255, 255, 0.1) 1px solid;
    border-right: rgba(255, 255, 255, 0.1) 1px solid;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
