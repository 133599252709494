<template>
  <div data-aos="fade">
    <PaddingComponent :padding="6" />
    <HorizontalLine />
    <div class="timestamp-container">
      <div class="timestamp-item left">{{ date }}</div>
      <div class="timestamp-item center">{{ place }}</div>
      <div class="timestamp-item right">{{ time }}</div>
    </div>
    <HorizontalLine />
    <PaddingComponent :padding="5" />
  </div>
</template>

<script>
import HorizontalLine from "@/components/subcomponents/HorizontalLine.vue";
import PaddingComponent from "@/components/subcomponents/PaddingComponent.vue";

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
    place: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
  },
  components: {
    HorizontalLine,
    PaddingComponent,
  },
};
</script>

<style lang="scss" scoped>
.timestamp-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.25rem;
  padding-bottom: 0.12rem;
  column-gap: 1rem;

  @include until($desktop) {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.timestamp-item {
  flex: 1;
  text-align: center;
  font-family: $family-monospace;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
</style>
