<template>
  <NavbarItem :alignment="'center'">
    <!-- <img :src="imageSource" /> -->
    <img v-if="$route.path === '/'" src="@/assets/svg/navbar_timeline_badge_active_2.svg" rel="preload" />
    <img v-else src="@/assets/svg/navbar_timeline_badge_2.svg" rel="preload" />
  </NavbarItem>
</template>

<script>
import NavbarItem from "./NavbarItem.vue";
export default {
  components: { NavbarItem },

  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
};
</script>

<style></style>
