<template>
  <div class="header" :class="[{ 'is-alternate': useAlternateGradient }]">
    <img
      class="background-image"
      :src="require(`@/assets/images/${filename}.png`)"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    filename: { type: String },
    isBlurGradient: { type: Boolean, default: false },
    useAlternateGradient: { type: Boolean, default: false },
    isFaded: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss">
.header {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: -1;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

// .background-image {
//   height: auto;
//   min-width: 1000px;
//   max-width: 100%;
//   width: 100%;
//   display: block;
//   z-index: 1;
// }

.header::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  mix-blend-mode: normal;
  z-index: 2;

  background: linear-gradient(
    352deg,
    #0d2e5d 32.38%,
    rgba(4, 37, 103, 0) 74.47%
  );
}

.header.is-alternate::before {
  background: linear-gradient(
    352deg,
    #002b45 32.38%,
    rgba(4, 37, 103, 0) 74.47%
  ) !important;
}
</style>
