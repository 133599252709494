// import 'bulma'

import "./assets/bulma.min.css";
import { createApp } from "vue";
import App from "./App.vue";

import "dropzone/dist/dropzone.css";

// Default theme
import "@splidejs/vue-splide/css";

// or other themes
// import '@splidejs/vue-splide/css/skyblue';
import "@splidejs/vue-splide/css/sea-green";

// or only core styles
import "@splidejs/vue-splide/css/core";

import "./assets/global.scss";

/* eslint-disable no-unused-vars */
//  var isMobile = null;
//  if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)){
//    isMobile = true;
//  } else {
//    isMobile = false;
// //    console.log("Not mobile");
//  }
/* eslint-enable no-unused-vars */

/* import the fontawesome core */
// import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// import { faMap } from "@fortawesome/free-regular-svg-icons";
// import { VueReCaptcha } from "vue-recaptcha-v3";

/* add icons to the library */
// library.add(faMap);

const app = createApp(App);
// app.use(VueReCaptcha, { siteKey: '6LekDrIkAAAAAH_zhsGMoM30PhMhk41mQfHnQeHc' })
// app.component("font-awesome-icon", FontAwesomeIcon).mount("#app");

import router from "./router";
app.use(router);

// import VueSmoothScroll from "vue3-smooth-scroll";
// app.use(VueSmoothScroll);

import VueSplide from "@splidejs/vue-splide";
app.use(VueSplide);

import i18n from "./i18n";
app.use(i18n);

app.mount("#app");

// app.config.globalProperties.isMobile = isMobile;
