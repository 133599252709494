<template>
  <section class="hero">
    <div class="hero-body">
      <p class="title">
        {{ $t("t1") }}
      </p>
      <p class="subtitle" v-html="$t('p1')"></p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.hero-body {
  padding-left: 0;
  @include from($desktop) {
    width: 75%;
  }
  @include until($desktop) {
    padding-left: 1em;
  }
}

.title {
  color: $third;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem; /* 154.545% */
}

.subtitle {
  color: $primary;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}
</style>
