<template>
  <div data-aos="fade" class="year" :class="{ 'is-large': isLarge }">
    {{ firstTwoDigits }}<br />{{ lastTwoDigits }}
  </div>
</template>

<script>
export default {
  props: {
    year: {
      type: String,
      required: true,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    firstTwoDigits() {
      return this.year.slice(0, 2);
    },
    lastTwoDigits() {
      return this.year.slice(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.year {
  font-family: $family-monospace;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.75rem;
}
.is-large {
  font-size: 7.5rem;
  line-height: 7.5rem;
}
</style>
