<template>
  <div :style="{ paddingTop: computedPadding + 'px' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      multiplier: 1.5,
      windowHeight: window.innerHeight,
    };
  },
  computed: {
    computedPadding() {
      // Calculate the padding based on the window height
      return (this.windowHeight * this.padding * this.multiplier) / 100;
    },
  },
  props: {
    padding: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    // Listen for the window resize event and update windowHeight
    // window.addEventListener("resize", this.updateWindowHeight);
    this.windowHeight = window.innerHeight;
  },
  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    // window.removeEventListener("resize", this.updateWindowHeight);
  },
  methods: {
    updateWindowHeight() {
      // Update the windowHeight data property when the window is resized
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>
