<template>
  <div
    class="horizontal-line"
    :style="{ 'border-top': `rgba(255, 255, 255, ${opacity}) 1px solid` }"
  ></div>
</template>

<script>
export default {
  props: {
    opacity: {
      type: Number,
      default: 0.5,
    },
  },
};
</script>

<style lang="scss">
.horizontal-line {
  position: absolute;
  pointer-events: none;

  left: -5rem !important;
  width: 150% !important; // calc(100vw) !important;
}
</style>
