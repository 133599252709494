<template>
  <div id="postcards" class="container is-fluid">
    <PageHeader
      data-aos="fade"
      data-aos-delay="500"
      :filename="'postcards_banner'"
      :useAlternateGradient="true"
    />
    <padding-component :padding="45" />
    <PageTitle :content="$t('n3')" />
    <horizontal-line />

    <padding-component :padding="12" />

    <div class="container">
      <PostcardComponent
        v-for="(item, index) in responseData"
        :key="index"
        :author="item.fullname"
        :imagePath="item.imgId"
        :text="item.infoTR"
        :date="new Date(item.date)"
      />
    </div>
    <PaddingComponent :padding="10" />
  </div>
</template>

<script>
import PageTitle from "@/components/subcomponents/PageTitle.vue";
import PaddingComponent from "./subcomponents/PaddingComponent.vue";
import HorizontalLine from "./subcomponents/HorizontalLine.vue";
// import TwoColumnRow from './subcomponents/TwoColumnRow.vue';
import PageHeader from "./subcomponents/PageHeader.vue";
import PostcardComponent from "./subcomponents/PostcardComponent.vue";
import HTTPRequester from "@/models/HTTPRequester";
import axios from "axios";

export default {
  data() {
    return {
      responseData: [], // Initialize with an empty array
    };
  },
  // Fetch your data from an API or wherever you get it
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        // Make your HTTP request to get the response data
        const response = await axios.get(
          HTTPRequester.get_request_endpoint_url(
            HTTPRequester.endpoints.postcard_list
          )
        );
        this.responseData = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  components: {
    PageTitle,
    PaddingComponent,
    HorizontalLine,
    // TwoColumnRow,
    PageHeader,
    PostcardComponent,
  },
};
</script>

<style lang="scss">
html {
  background: #0d2e5d !important;
}
</style>

<style lang="scss" scoped>
.title {
  color: #fef7c2;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem; /* 106.25% */
}
.subtitle {
  color: $secondary;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem; /* 212.5% */
}
.text {
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 175% */
}
</style>
