// router.js
import { createRouter, createWebHistory } from "vue-router";
import TimelinePage from "@/components/TimelinePage.vue";
import MethodologyPage from "@/components/MethodologyPage.vue";
import MakingOfPage from "@/components/MakingOfPage.vue";
import PostcardsPage from "@/components/PostcardsPage.vue";
import ContributePage from "@/components/ContributePage.vue";

const routes = [
  { path: "/methodology", component: MethodologyPage },
  { path: "/makingof", component: MakingOfPage },
  { path: "/", component: TimelinePage },
  { path: "/postcards", component: PostcardsPage },
  { path: "/contribute", component: ContributePage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
    if (savedPosition) {
      // If a saved position exists, return to that position
      return savedPosition;
    } else {
      // Otherwise, scroll to the top of the page
      return { top: 0 };
    }
  },
});

export default router;
